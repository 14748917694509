import React from "react";

function Policy() {
  return (
    <div
      style={{
        paddingTop: 125,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 50,
      }}
    >
      <h1 style={{ fontSize: "1.75rem", marginBottom: 10 }}>
        Политика конфиденциальности
      </h1>
      ПОЛИТИКА ЗАЩИТЫ И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
      <br />
      <br />
      &nbsp;ООО «ГринМос» <br />
      <br />
      &nbsp;1. Общие положения <br />
      <br />
      &nbsp;1.1. Настоящая Политика в отношении обработки персональных данных
      (далее – Политика) составлена в соответствии с пунктом 2 статьи 18.1
      Федерального закона «О персональных данных» № 152-ФЗ от 27 июля 2006 г., а
      также иными нормативными правовыми актами Российской Федерации в области
      защиты и обработки персональных данных и действует в отношении всех
      персональных данных (далее – данные), которые Организация (далее –
      Оператор, Общество) может получить от субъекта персональных данных,
      являющегося стороной по гражданско-правовому договору, от пользователя
      сети Интернет (далее – Пользователь) во время использования им любого из
      сайтов, сервисов, служб, программ, продуктов или услуг ООО «ГринМос», а
      также от субъекта персональных данных, состоящего с Оператором в
      отношениях, регулируемых трудовым законодательством (далее – Работник).
      <br />
      <br />
      1.2. Оператор обеспечивает защиту обрабатываемых персональных данных от
      несанкционированного доступа и разглашения, неправомерного использования
      или утраты в соответствии с требованиями Федерального закона от 27 июля
      2006 г. № 152-ФЗ «О персональных данных». <br />
      <br />
      &nbsp;1.3. Оператор вправе вносить изменения в настоящую Политику. При
      внесении изменений в заголовке Политики указывается дата последнего
      обновления редакции. Новая редакция Политики вступает в силу с момента ее
      размещения на сайте, если иное не предусмотрено новой редакцией Политики.{" "}
      <br />
      <br />
      &nbsp;2. Термины и принятые сокращения <br />
      <br />
      Персональные данные – любая информация, относящаяся к прямо или косвенно
      определенному или определяемому физическому лицу (субъекту персональных
      данных). <br />
      <br />
      Обработка персональных данных – любое действие (операция) или совокупность
      действий (операций), совершаемых с использованием средств автоматизации
      или без использования таких средств с персональными данными, включая сбор,
      запись, систематизацию, накопление, хранение, уточнение (обновление,
      изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление,
      уничтожение персональных данных. <br />
      <br />
      Автоматизированная обработка персональных данных – обработка персональных
      данных с помощью средств вычислительной техники.
      <br />
      <br />
      &nbsp;Информационная система персональных данных (ИСПД) – совокупность
      содержащихся в базах данных персональных данных и обеспечивающих их
      обработку информационных технологий и технических средств. <br />
      <br />
      Персональные данные, сделанные общедоступными субъектом персональных
      данных, – персональные данные, доступ неограниченного круга лиц к которым
      предоставлен субъектом персональных данных либо по его просьбе. <br />
      <br />
      Блокирование персональных данных – временное прекращение обработки
      персональных данных (за исключением случаев, если обработка необходима для
      уточнения персональных данных). <br />
      <br />
      Уничтожение персональных данных – действия, в результате которых
      становится невозможным восстановить содержание персональных данных в
      информационной системе персональных данных и (или) в результате которых
      уничтожаются материальные носители персональных данных. <br />
      <br />
      Оператор – организация, самостоятельно или совместно с другими лицами
      организующая обработку персональных данных, а также определяющая цели
      обработки персональных данных, подлежащих обработке, действия (операции),
      совершаемые с персональными данными. Оператором является
      ______________________, расположенное по адресу: __________________.{" "}
      <br />
      <br />
      &nbsp;3. Обработка персональных данных <br />
      <br />
      3.1. Получение персональных данных. <br />
      <br />
      3.1.1. Все персональные данные следует получать от самого субъекта. Если
      персональные данные субъекта можно получить только у третьей стороны, то
      субъект должен быть уведомлен об этом или от него должно быть получено
      согласие. <br />
      <br />
      3.1.2. Оператор должен сообщить субъекту о целях, предполагаемых
      источниках и способах получения персональных данных, характере подлежащих
      получению персональных данных, перечне действий с персональными данными,
      сроке, в течение которого действует согласие, и порядке его отзыва, а
      также о последствиях отказа субъекта дать письменное согласие на их
      получение. <br />
      <br />
      3.1.3. Документы, содержащие персональные данные, создаются путем: –
      копирования оригиналов документов (паспорт, документ об образовании,
      свидетельство ИНН, пенсионное свидетельство и др.); – внесения сведений в
      учетные формы; – получения оригиналов необходимых документов (трудовая
      книжка, медицинское заключение, характеристика и др.).
      <br />
      <br />
      &nbsp;3.2. Обработка персональных данных. <br />
      <br />
      3.2.1. Обработка персональных данных осуществляется: – с согласия субъекта
      персональных данных на обработку его персональных данных; – в случаях,
      когда обработка персональных данных необходима для осуществления и
      выполнения возложенных законодательством РФ функций, полномочий и
      обязанностей; – в случаях, когда осуществляется обработка персональных
      данных, доступ неограниченного круга лиц к которым предоставлен субъектом
      персональных данных либо по его просьбе (далее – персональные данные,
      сделанные общедоступными субъектом персональных данных). <br />
      <br />
      3.2.2. Цели обработки персональных данных: – осуществление трудовых
      отношений; – осуществление гражданско-правовых отношений; – для связи с
      пользователем, в связи с заполнением формы обратной связи на сайте, в том
      числе направление уведомлений, запросов и информации, касающихся
      использования сайта магазина, обработки, согласования заказов и их
      доставки, исполнения соглашений и договоров; - обезличивания персональных
      данных для получения обезличенных статистических данных, которые
      передаются третьему лицу для проведения исследований, выполнения работ или
      оказания услуг по поручению магазина. <br />
      <br />
      3.2.3. Категории субъектов персональных данных. Обрабатываются
      персональные данные следующих субъектов персональных данных: – физические
      лица, состоящие с Обществом в трудовых отношениях; – физические лица,
      уволившиеся из Общества; – физические лица, являющиеся кандидатами на
      работу; – физические лица, состоящие с Обществом в гражданско-правовых
      отношениях; – физические лица, являющиеся Пользователями Сайта Магазина.{" "}
      <br />
      <br />
      3.2.4. Персональные данные, обрабатываемые Оператором: – данные,
      полученные при осуществлении трудовых отношений; – данные, полученные для
      осуществления отбора кандидатов на работу; – данные, полученные при
      осуществлении гражданско-правовых отношений; – данные, полученные от
      Пользователей Сайта Магазина. <br />
      <br />
      3.2.5. Обработка персональных данных ведется: – с использованием средств
      автоматизации; – без использования средств автоматизации. <br />
      <br />
      3.3. Хранение персональных данных.
      <br />
      <br />
      &nbsp;3.3.1. Персональные данные субъектов могут быть получены, проходить
      дальнейшую обработку и передаваться на хранение как на бумажных носителях,
      так и в электронном виде.
      <br />
      <br />
      &nbsp;3.3.2. Персональные данные, зафиксированные на бумажных носителях,
      хранятся в запираемых шкафах либо в запираемых помещениях с ограниченным
      правом доступа. <br />
      <br />
      3.3.3. Персональные данные субъектов, обрабатываемые с использованием
      средств автоматизации в разных целях, хранятся в разных папках. <br />
      <br />
      3.3.4. Не допускается хранение и размещение документов, содержащих
      персональных данных, в открытых электронных каталогах (файлообменниках) в
      ИСПД. <br />
      <br />
      3.3.5. Хранение персональных данных в форме, позволяющей определить
      субъекта персональных данных, осуществляется не дольше, чем этого требуют
      цели их обработки, и они подлежат уничтожению по достижении целей
      обработки или в случае утраты необходимости в их достижении. <br />
      <br />
      3.4. Уничтожение персональных данных. <br />
      <br />
      3.4.1. Уничтожение документов (носителей), содержащих персональных данных,
      производится путем сожжения, дробления (измельчения), химического
      разложения, превращения в бесформенную массу или порошок. Для уничтожения
      бумажных документов допускается применение шредера. <br />
      <br />
      3.4.2. Персональные данные на электронных носителях уничтожаются путем
      стирания или форматирования носителя. <br />
      <br />
      3.4.3. Факт уничтожения персональных данных подтверждается документально
      актом об уничтожении носителей. <br />
      <br />
      3.5. Передача персональных данных. <br />
      <br />
      3.5.1. Оператор передает персональные данные третьим лицам в следующих
      случаях: – субъект выразил свое согласие на такие действия; – передача
      предусмотрена российским или иным применимым законодательством в рамках
      установленной законодательством процедуры.
      <br />
      <br />
      &nbsp;3.5.2. Перечень лиц, которым передаются персональные данные. –
      Пенсионный фонд РФ для учета (на законных основаниях); – налоговые органы
      РФ (на законных основаниях); – Фонд социального страхования РФ (на
      законных основаниях); – территориальный фонд обязательного медицинского
      страхования (на законных основаниях); – страховые медицинские организации
      по обязательному и добровольному медицинскому страхованию (на законных
      основаниях); – банки для начисления заработной платы (на основании
      договора); – органы МВД России в случаях, установленных законодательством;
      – обезличенные персональные данные Пользователей сайта интернет-магазина
      передаются контрагентам Магазина. <br />
      <br />
      &nbsp;4. Защита персональных данных <br />
      <br />
      4.1. В соответствии с требованиями нормативных документов Оператором
      создана система защиты персональных данных (СЗПД), состоящая из подсистем
      правовой, организационной и технической защиты. <br />
      <br />
      4.2. Подсистема правовой защиты представляет собой комплекс правовых,
      организационно-распорядительных и нормативных документов, обеспечивающих
      создание, функционирование и совершенствование СЗПД. <br />
      <br />
      4.3. Подсистема организационной защиты включает в себя организацию
      структуры управления СЗПД, разрешительной системы, защиты информации при
      работе с сотрудниками, партнерами и сторонними лицами. <br />
      <br />
      4.4. Подсистема технической защиты включает в себя комплекс технических,
      программных, программно-аппаратных средств, обеспечивающих защиту
      персональных данных. <br />
      <br />
      4.4. Основными мерами защиты персональных данных, используемыми
      Оператором, являются: <br />
      <br />
      4.5.1. Назначение лица, ответственного за обработку персональных данных,
      которое осуществляет организацию обработки персональных данных, обучение и
      инструктаж, внутренний контроль за соблюдением учреждением и его
      работниками требований к защите персональных данных. <br />
      <br />
      4.5.2. Определение актуальных угроз безопасности персональных данных при
      их обработке в ИСПД и разработка мер и мероприятий по защите персональных
      данных. <br />
      <br />
      4.5.3. Разработка политики в отношении обработки персональных данных.{" "}
      <br />
      <br />
      4.5.4. Установление правил доступа к персональных данных, обрабатываемым в
      ИСПД, а также обеспечение регистрации и учета всех действий, совершаемых с
      персональными данными в ИСПД. <br />
      <br />
      4.5.5. Установление индивидуальных паролей доступа сотрудников в
      информационную систему в соответствии с их производственными
      обязанностями.
      <br />
      <br />
      &nbsp;4.5.6. Применение прошедших в установленном порядке процедуру оценки
      соответствия средств защиты информации. <br />
      <br />
      4.5.7. Сертифицированное антивирусное программное обеспечение с регулярно
      обновляемыми базами. <br />
      <br />
      4.5.8. Соблюдение условий, обеспечивающих сохранность персональных данных
      и исключающих несанкционированный к ним доступ. <br />
      <br />
      4.5.9. Обнаружение фактов несанкционированного доступа к персональным
      данным и принятие мер. <br />
      <br />
      4.5.10. Восстановление персональных данных, модифицированных или
      уничтоженных вследствие несанкционированного доступа к ним. <br />
      <br />
      4.5.11. Обучение работников Оператора, непосредственно осуществляющих
      обработку персональных данных, положениям законодательства РФ о
      персональных данных, в том числе требованиям к защите персональных данных,
      документам, определяющим политику Оператора в отношении обработки
      персональных данных, локальным актам по вопросам обработки персональных
      данных. <br />
      <br />
      4.5.12. Осуществление внутреннего контроля и аудита.
      <br />
      <br />
      &nbsp;5. Основные права субъекта персональных данных и обязанности
      Оператора <br />
      <br />
      5.1. Основные права субъекта персональных данных. <br />
      <br />
      Субъект имеет право на доступ к его персональным данным и следующим
      сведениям: – подтверждение факта обработки персональных данных Оператором;
      – правовые основания и цели обработки персональных данных; – цели и
      применяемые Оператором способы обработки персональных данных; –
      наименование и место нахождения Оператора, сведения о лицах (за
      исключением работников Оператора), которые имеют доступ к персональных
      данных или которым могут быть раскрыты персональные данные на основании
      договора с Оператором или на основании федерального закона; – сроки
      обработки персональных данных, в том числе сроки их хранения; – порядок
      осуществления субъектом персональных данных прав, предусмотренных
      Федеральным законом; – наименование или фамилия, имя, отчество и адрес
      лица, осуществляющего обработку персональных данных по поручению
      Оператора, если обработка поручена или будет поручена такому лицу; –
      обращение к Оператору и направление ему запросов; – обжалование действий
      или бездействия Оператора. <br />
      <br />
      5.2. Обязанности Оператора. <br />
      <br />
      Оператор обязан: – при сборе персональных данных предоставить информацию
      об обработке персональных данных; – в случаях если персональные данные
      были получены не от субъекта персональных данных, уведомить субъекта; –
      при отказе в предоставлении персональных данных субъекту разъясняются
      последствия такого отказа; – опубликовать или иным образом обеспечить
      неограниченный доступ к документу, определяющему его политику в отношении
      обработки персональных данных, к сведениям о реализуемых требованиях к
      защите персональных данных; – принимать необходимые правовые,
      организационные и технические меры или обеспечивать их принятие для защиты
      персональных данных от неправомерного или случайного доступа к ним,
      уничтожения, изменения, блокирования, копирования, предоставления,
      распространения персональных данных, а также от иных неправомерных
      действий в отношении персональных данных; – давать ответы на запросы и
      обращения субъектов персональных данных, их представителей и
      уполномоченного органа по защите прав субъектов персональных данных.{" "}
    </div>
  );
}

export default Policy;
